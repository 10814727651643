/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: dodgerblue;
  color: #000000;
}
.container {
  display: flex;
  justify-content: center;
  height: 100vh;
}
.page-border {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  border-radius: 10px;
  padding: 25px;
  margin: 50px 70px 50px 70px;
  max-width: 500px;
  margin: auto ;
}

/* Other Global Styles */

.submit-btn-style {
  background: #1b72ab;
  color: white;
  height: 35px;
  width: 120px;
  border: none;
  border-radius: 5px;
  margin: 1rem 0;
  font-size: 0.9rem;
}

.orange {
  background: #faa632;
}

.nav-bar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 30px;
}

.top-logo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: 25px;
}

.white-wings {
  display: flex;
  justify-content: center;
  height: auto;
}

.logo-title {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 56px;
  font-weight: bold;
  margin-top: 11px;
}

.buttonBorder {
  display: flex;
  justify-content: center;
  border-top: 1px solid white;
  padding-top: 25px;
}

.btn-margin {
  width: 300px;
  height: 65px;
  margin: 0 auto;
  margin-bottom: 15px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  font-size: 24px;
}

.example {
  display: none;
}

.pirep {
  position: absolute;
  left: 10px;
  top: 100px;
}

.postform {
  margin: auto;
  font-size: 18px;
}

.pirep-report {
  font-size: 18px;
}

.plan-label {
  font-weight: bold;
  color: white;
}

/* Distance Display */

.flightDiv {
  color: white;
  position: absolute;
  top: 200px;
  /* background: #FAA632; */
  text-align: center;
  padding:10px;
  
}

.flightDiv p {
  margin-top: 10px;
}

/* Flight Plan Styles */

.plan-flight-container {
  max-width: 448px;
  text-align: center;
}

.custom-style-location {
  width: 400px;
  margin-bottom: 1rem;
  border: none;
}

.custom-style-list {
  background: white;
  font-size: 1.2rem;
  line-height: 1.5;
}

li.custom-style-list:hover {
  background: #1b72ab;
  cursor: pointer;
}

.plan-flight p {
  margin-bottom: 10px;
  font-size: 1.125rem;
}

/* Flight Plan Media Queries */

@media (max-width: 600px) {
  .custom-style-location {
    width: 100%;
  }
}

/* Update Hour Window Form */

.hourInput {
  font-weight:bold;
  
}

.hourForm {
  position: absolute;
  left: 10px;
  top: 400px;
  background: #faa632;
  text-align: center;
  padding:10px;
  
}

.mobilehourForm {
  text-align: center;
}

.hourForm p {
  margin-bottom: 10px;
}
/* hour window input */

.custom-style {
  border: none;
  background: white;
  width:100px;
  margin-right:10px
}
.hour-button-container{
  background:none;
  border:none;
}
@media(max-width: 400px) {
  .custom-style {
    margin-right:0px
  }
}

/* PIREP entry form */

.dialog-title {
  text-align: center;
}

.pirep-form {
  text-align: center;
}

.pirep-form .form-flex {
  display: flex;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.pirep-form .left-border {
  border-left: 1px solid black;
}

.pirep-form .form-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  font-size: 1rem;
  width: 50%;
  padding: 10px 0;
  line-height: 1;
}

.pirep-form .form-column h1 {
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.pirep-form input {
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  border: none;
}

.pirep-form .form-column input {
  margin-right: 0.5rem;
}

/* Form Column Media Queries */

@media (max-width: 500px) {
  .pirep-form .form-flex {
    flex-direction: column;
  }

  .pirep-form .form-column {
    width: 100%;
  }

  .pirep-form .icing {
    border-top: 1px solid black;
    border-left: none;
  }

  .altitude-label {
    margin-bottom: 1rem;
  }

  .altitude input {
    width: 100%;
    margin-left: 0;
  }


}

/* weather icons */

.weather h1 {
  margin-top: 1rem;
}

.weather img {
  width: 30px;
}

.weather-flex {
  display: flex;
  justify-content: space-around;
  /* background:  #f8f8ff; */
  padding: 15px 0 15px 0;
  /* border-radius: 5px; */
  border-bottom: 1px solid black;
}

.weather-background {
  background: #f8f8ff;
  border-radius: 20px;
  padding: 5px 5px 0px 0px;
  margin: 5px;
}

.weather-column {
  display: flex;
  flex-direction: column;
  text-align: left;
}

/* description, lat, and long inputs */

.bottom-form-inputs {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  /* border-top: 1px solid black; */
  padding: 20px 10px 0 10px;
}

.description,
.latitude,
.longitude {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

/* description, lat, long Form Media Queries */

@media (max-width: 500px) {
  .description,
  .latitude,
  .longitude {
    flex-direction: column;
  }

  .bottom-form-inputs label {
    margin-bottom: 10px;
  }
}

.hourformdialog{
  width: 300px;
  height:200px;
  margin:auto;
}

.hourformdialog-container {
  height:auto;
  width:auto

}

/* Info Window */

.info-window {
  background: white;
}

.pirep-info {
  line-height: 2;
}

.pirep-info .pirep-title {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

.pirep-info strong {
  font-weight: bold;
}

.pirep-info img {
  margin-left: 0.5rem;
}

.pirep-info .weather-tag {
  display: flex;
  align-items: center;
}

/* mobile menu */
.mobileMenu{
  display:none;
  position: absolute;
  left:10px;
  top: 100px;
}
.menu-button {
  width:130px;
}
/* mobile display buttons */
@media(max-width: 500px) {
  .mobileMenu{
    display:block;
  }
  .hourForm{
    display:none;
  }
  .flightDiv{
    display:none;
  }
  .pirep {
    display:none;
  }
  .logoutButton{
    display:none;
  }
}

.logoutButton{
  position: absolute;
  left:10px;
  top: 320px;
}
